import React, { useState, useEffect } from "react"
import { _, Emitter, REQ, store,  Can /*, a*/ } from "../store/psrs"
import { /*useMyContext,*/ useAbilityContext } from "../store/useMyContext"
// import useSWR, { mutate } from "swr"
import { Checkbox, Input, Layout, Button } from "antd"
import { Breadcrumb, Icon, Tag } from "antd"
import { TagSelect } from "./Forms/TagSelect"
import { AssignmentSelect } from "./Forms/AssignmentSelect"
// import { employeeSelect } from "./Forms/employeeSelect"
import { Accordion, AccordionPanel, Box, TableBody } from "grommet"
import { Table, TableHeader, TableRow, TableCell } from "grommet"
import Select from "react-select"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/dist/style.css"
import stc from "string-to-color"
//import { update } from "lodash"
import uuid from "uuid"

const { Content } = Layout

export const Employee = props => {
  const mySettings = props.match.params.num ? false : true
  const ability = useAbilityContext()
  const canEditRole = ability.can("edit:employee:Role")

  const [state, setState] = useState({
    mounted: false,
    loading: false,
    employeeNumber: mySettings ? store.session.get("CurrentUser").number : props.match.params.num,
  })
  const _employee = store.get("Employee") || false
  const [employee, setEmployee] = useState(_employee)

  // const fetcher = url => {
  //   const options = { ...reqOptions }
  //   return fetch(url, options)
  //     .then(res => res.json())
  //     .catch(err => Emitter.emit("employee.js", { fn: "fetcher", error: err }))
  // }
  // const key = `${store.get("api")}/api/employees/${state.employeeNumber}`
  // const { data } = useSWR(key, fetcher)

  // const [phone, setPhone] = useState({ valid: false, number: "7606811607" })

  // WIP - Notification enhancment
  //const [active, setActive] = useState([0, 1, 3, 4])
  const [active, setActive] = useState([0, 1, 2, 3])

  const fetchEmployee = async () => {
    //console.log("fetchEmployee")
    const E = await REQ(`/api/employees/${state.employeeNumber}`)
    setEmployee(E)
    store.set("Employee", E)
  }

  const updateSettings = async ({ field, value }) => {
    const { settings } = employee
    settings[field] = value
    setEmployee({ ...employee, settings })
    //console.log(`updateSettings > ${field}: ${value}`, settings)
    const updated = await REQ(`/api/employees/${state.employeeNumber}`, "PATCH", { settings }).catch(err =>
      Emitter.emit("employee.js", { fn: "updateSettings", error: err }),
    )
    store.set("Employee", updated)
    setEmployee(updated)
  }

  /* WIP Notification Subscription update.
     Remember to move to backend for enable/disabled logic
  const updateNotificationSettings = async ({ type, field, value }) => {
    let { settings } = employee

    const notificationTypes = {
      otRequest: true,
      ptoRequest: true,
      sickRequest: true,
      bidRequest: true,
      otResponse: true,
      ptoResponse: true,
      sickResponse: true,
      bidAwarded: true,
      bidRevoked: true,
      bidLost: true,

    }
    const notificationMap = {
      otRequestNotify:   {otRequest:   {notify: value}} ,
      otReqSendToEmail1: {otRequest: {sendToEmail1: value}},
      otReqSendToEmail2: {otRequest: {sendToEmail2: value}},
      otReqSendToPhone1: {otRequest: {sendToPhone1: value}},
      otReqSendToPhone2: {otRequest: {sendToPhone2: value}},
      ptoRequestNotify:  { ptoRequest: {notify: value}},
      ptoReqSendToEmail1: {ptoRequest: {sendToEmail1: value}},
      ptoReqSendToEmail2: {ptoRequest: {sendToEmail2: value}},
      ptoReqSendToPhone1: {ptoRequest: {sendToPhone1: value}},
      sickRequestNotify: { sickRequest: {notify: value}},
      sickReqSendToEmail1: {sickRequest: {sendToEmail1: value}},
      sickReqSendToEmail2: {sickRequest: {sendToEmail2: value}},
      sickReqSendToPhone1: {sickRequest: {sendToPhone1: value}},
      
    }

    const notification = {}
    const empSettings = employee.settings

    //let notification = notificationMap[field]

    if (notificationTypes[type] && field === 'notify'){
      if (!value) {
        settings.notification[type]['inApp'] = false
        settings.notification[type]['sendToEmail1'] = false
        settings.notification[type]['sendToEmail2'] = false
        settings.notification[type]['sendToPhone1'] = false
        settings.notification[type]['sendToPhone2'] = false
      } else {
        if ( !settings.notification[type]['sendToEmail1'] && !settings.notification[type]['sendToEmail2'] && !settings.notification[type]['sendToPhone1'] && !settings.notification[type]['sendToPhone2']) {
          settings.notification[type]['inApp'] = true
        }
      }
    }
      
      
    notification[type]= {}
    notification[type][field] = value

    settings.notification[type][field] = value

    //settings = {...settings, notification}
    //settings[field] = value
    
    if (field !== 'notify'){
      if ( !settings.notification[type]['sendToEmail1'] && !settings.notification[type]['sendToEmail2'] && !settings.notification[type]['sendToPhone1'] && !settings.notification[type]['sendToPhone2']) {
        settings.notification[type]['inApp'] = true
      }
    }

    setEmployee({ ...employee, settings })
    console.log(`updateNotificationSettings > ${field}: ${value}`, settings)
    const updated = await REQ(`/api/employees/${state.employeeNumber}`, "PATCH", { settings }).catch(err =>
      Emitter.emit("employee.js", { fn: "updateSettings", error: err }),
    )
    store.set("Employee", updated)
    setEmployee(updated)
  }
  * End of notification subscriptions */

  const updateEmployee = async ({ field, value }) => {
    const update = {}
    update[field] = value
    //console.log("\nupdateEmployee > updated", update)
    setEmployee({ ...employee, ...update })
    const updated = await REQ(`/api/employees/${state.employeeNumber}`, "PATCH", update).catch(err =>
      Emitter.emit("employee.js", { fn: "updateEmployee", error: err }),
    )
    store.set("Employee", updated)
    setEmployee(updated)
  }

  const phoneProps = {
    enableAreaCodes: ["ca"],
    enableAreaCodeStretch: true,
    disableCountryCode: true,
    disableDropdown: true,
    defaultCountry: "us",
    onlyCountries: ["us"],
    copyNumbersOnly: true,
  }

  const Verify = props => {
    const { field, disabled } = props
    if (!!employee.settings[field]) {
      return "Verified"
    } else {
      return <Button 
        disabled={disabled ? true : false}
        //onClick={async ()=> await REQ(`/api/auth0/merge/${state.employeeNumber}`)}  
        //onClick={async () => await REQ(`/api/auth0/verify_sms/${state.employeeNumber}/${field}`)}  
        onClick={async () => await REQ(`/api/auth0/silent_invite/${state.employeeNumber}/${field}`)}  

      >Verify</Button>
    }
  }

  const SilentInvite = ({ field, value }) => {
    const btnOpts = {
      disabled: !!employee[field] ? false : true,
      onClick: async () => {
        //console.log(`SILENT INVITE: ${value}`)
        const RES = await REQ(`/api/auth0/silent_invite/${state.employeeNumber}/${field}`)
        console.log("SILENT INVITE RES", RES)
      },
    }
    return <Button  style={employee.auth0User ? { background: "lightgreen", borderColor: "black" } :{ background: "lemonchiffon", borderColor: "black" } } {...btnOpts}>Silent Invite</Button>
  }

  const SyncWithJDE = ({ field, value }) => {
    const btnOpts = {
      disabled: true,
      onClick: async () => {
        //console.log(`Sync with JDE: ${value}`)
        //const RES = await REQ(`/api/auth0/silent_invite/${state.employeeNumber}/${field}`)
        //console.log("Sync with JDE RES", RES)
      },
    }
    return <Button  style={ { background: "lightgreen", borderColor: "black" } } {...btnOpts}>Sync with Oracle (JDE)</Button>
  }

  const TH = { scope: "col", border: "bottom" }
  const TD = { scope: "row" }

  /* WIP notification subscription
  const THC = { scope: "col", border: "bottom", align: "center"}
  const TDC = {scope: "row", align: "center"}

  const NotificationRequestTable = () => {

    const superDisabled = store.session.get("CurrentUser").role !== 'employee' ? false : true

    const employeeDisabled = false
    const settings = {}
  
    return ( !superDisabled &&
      <Box background="white" pad="medium" margin="medium">
      <Table className="EmployeeNotificationTable">
        <TableHeader>
          <TableRow>
            <TableCell {...TH}>Supervisors Notifications</TableCell>
            <TableCell {...THC}>In App Notfication</TableCell>
            <TableCell {...THC}>Send to Oracle Email</TableCell>
            <TableCell {...THC}>Send to Oracle Phone</TableCell>
            <TableCell {...THC}>Send to Secondary Email</TableCell>
            <TableCell {...THC}>Send to Secondary Phone</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={superDisabled}
                checked={employee.settings.notification ? employee.settings.notification.otRequest.notify : false}
                onChange={e => updateNotificationSettings({ type: 'otRequest', field: "notify", value: e.target.checked })}
              >{<span>Overtime Approval Request</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.otRequest.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'otRequest', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.otRequest.sendToEmail1 : false}
                onChange={e => updateNotificationSettings({ type: 'otRequest', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.otRequest.sendToPhone1 :false}
                onChange={e => updateNotificationSettings({ type: 'otRequest', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.otRequest.sendToEmail2 : false}
                onChange={e => updateNotificationSettings({ type: 'otRequest', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.otRequest.sendToPhone2 :false}
                onChange={e => updateNotificationSettings({ type: 'otRequest', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            </TableRow>
            <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={superDisabled}
                checked={employee.settings.notification.sickRequest.notify ? employee.settings.notification.sickRequest.notify : false}
                onChange={e => updateNotificationSettings({ type: 'sickRequest', field: "notify", value: e.target.checked })}
              >{<span>Sick Approval Request</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.sickRequest.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'sickRequest', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.sickRequest.sendToEmail1 : false}
                onChange={e => updateNotificationSettings({ type: 'sickRequest', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.sickRequest.sendToPhone1 : false}
                onChange={e => updateNotificationSettings({ type: 'sickRequest', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.sickRequest.sendToEmail2 : false}
                onChange={e => updateNotificationSettings({ type: 'sickRequest', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.sickRequest.sendToPhone2 : false}
                onChange={e => updateNotificationSettings({ type: 'sickRequest', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={superDisabled}
                checked={ employee.settings.notification.ptoRequest.notify || false}
                onChange={e => updateNotificationSettings({ type: 'ptoRequest', field: "notify", value: e.target.checked })}
              >{<span>Leave Approval Request</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.ptoRequest.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'ptoRequest', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.ptoRequest.sendToEmail1 : false}
                onChange={e => updateNotificationSettings({ type: 'ptoRequest', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.ptoRequest.sendToPhone1 : false}
                onChange={e => updateNotificationSettings({ type: 'ptoRequest', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.ptoRequest.sendToEmail2 : false}
                onChange={e => updateNotificationSettings({ type: 'ptoRequest', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.ptoRequest.sendToPhone2 : false}
                onChange={e => updateNotificationSettings({ type: 'ptoRequest', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={superDisabled}
                checked={employee.settings.notification ? employee.settings.notification.bidRequest.notify : false}
                onChange={e => updateNotificationSettings({ type: 'bidRequest', field: "notify", value: e.target.checked })}
              >{<span>Shift Bid Request</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidRequest.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'bidRequest', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidRequest.sendToEmail1 : false}
                onChange={e => updateNotificationSettings({ type: 'bidRequest', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidRequest.sendToPhone1 : false}
                onChange={e => updateNotificationSettings({ type: 'bidRequest', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidRequest.sendToPhone2 : false}
                onChange={e => updateNotificationSettings({ type: 'bidRequest', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRequest.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidRequest.sendToEmail2 : false}
                onChange={e => updateNotificationSettings({ type: 'bidRequest', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </Box>
    )
  }

  const NotificationResponseTable = () => {

    const superDisabled = false
    const employeeDisabled = false
 
    return (
      <Table className="EmployeeNotificationTable">
        <TableHeader>
          <TableRow>
            <TableCell {...TH}>Employees Notifications</TableCell>
            <TableCell {...THC}>In App Notfication</TableCell>
            <TableCell {...THC}>Send to Oracle Email</TableCell>
            <TableCell {...THC}>Send to Oracle Phone</TableCell>
            <TableCell {...THC}>Send to Secondary Email</TableCell>
            <TableCell {...THC}>Send to Secondary Phone</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={employeeDisabled}
                checked={employee.settings.notification.otResponse.notify}
                onChange={e => updateNotificationSettings({ type: 'otResponse', field: "notify", value: e.target.checked })}
              >{<span>Overtime Approved/Rejected </span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otResponse.notify}
                checked={employee.settings.notification ? employee.settings.notification.otResponse.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'otResponse', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otResponse.notify}
                checked={employee.settings.notification.otResponse.sendToEmail1}
                onChange={e => updateNotificationSettings({ type: 'otResponse', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otResponse.notify}
                checked={employee.settings.notification.otResponse.sendToPhone1}
                onChange={e => updateNotificationSettings({ type: 'otResponse', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otResponse.notify}
                checked={employee.settings.notification.otResponse.sendToEmail2}
                onChange={e => updateNotificationSettings({ type: 'otResponse', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.otResponse.notify}
                checked={employee.settings.notification.otResponse.sendToPhone2}
                onChange={e => updateNotificationSettings({ type: 'otResponse', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            </TableRow>
            <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={employeeDisabled}
                checked={employee.settings.notification.sickResponse.notify}
                onChange={e => updateNotificationSettings({ type: 'sickResponse', field: "notify", value: e.target.checked })}
              >{<span>Sick Approved/Rejected </span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickResponse.notify}
                checked={employee.settings.notification ? employee.settings.notification.sickResponse.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'sickResponse', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickResponse.notify}
                checked={employee.settings.notification.sickResponse.sendToEmail1}
                onChange={e => updateNotificationSettings({ type: 'sickResponse', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickResponse.notify}
                checked={employee.settings.notification.sickResponse.sendToPhone1}
                onChange={e => updateNotificationSettings({ type: 'sickResponse', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickResponse.notify}
                checked={employee.settings.notification.sickResponse.sendToEmail2}
                onChange={e => updateNotificationSettings({ type: 'sickResponse', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.sickResponse.notify}
                checked={employee.settings.notification.sickResponse.sendToPhone2}
                onChange={e => updateNotificationSettings({ type: 'sickResponse', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={employeeDisabled}
                checked={employee.settings.notification.ptoResponse.notify}
                onChange={e => updateNotificationSettings({ type: 'ptoResponse', field: "notify", value: e.target.checked })}
              >{<span>Leave Approved/Rejected </span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoResponse.notify}
                checked={employee.settings.notification ? employee.settings.notification.ptoResponse.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'ptoResponse', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoResponse.notify}
                checked={employee.settings.notification.ptoResponse.sendToEmail1}
                onChange={e => updateNotificationSettings({ type: 'ptoResponse', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoResponse.notify}
                checked={employee.settings.notification.ptoResponse.sendToPhone1}
                onChange={e => updateNotificationSettings({ type: 'ptoResponse', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoResponse.notify}
                checked={employee.settings.notification.ptoResponse.sendToEmail2}
                onChange={e => updateNotificationSettings({ type: 'ptoResponse', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.ptoResponse.notify}
                checked={employee.settings.notification.ptoResponse.sendToPhone2}
                onChange={e => updateNotificationSettings({ type: 'ptoResponse', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={employeeDisabled}
                checked={employee.settings.notification.bidAwarded.notify}
                onChange={e => updateNotificationSettings({ type: 'bidAwarded', field: "notify", value: e.target.checked })}
              >{<span>Shift Bid Awarded</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidAwarded.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidAwarded.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'bidAwarded', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidAwarded.notify}
                checked={employee.settings.notification.bidAwarded.sendToEmail1}
                onChange={e => updateNotificationSettings({ type: 'bidAwarded', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidAwarded.notify}
                checked={employee.settings.notification.bidAwarded.sendToPhone1}
                onChange={e => updateNotificationSettings({ type: 'bidAwarded', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidAwarded.notify}
                checked={employee.settings.notification.bidAwarded.sendToEmail2}
                onChange={e => updateNotificationSettings({ type: 'bidAwarded', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidAwarded.notify}
                checked={employee.settings.notification.bidAwarded.sendToPhone2}
                onChange={e => updateNotificationSettings({ type: 'bidAwarded', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={employeeDisabled}
                checked={employee.settings.notification.bidRevoked.notify}
                onChange={e => updateNotificationSettings({ type: 'bidRevoked', field: "notify", value: e.target.checked })}
              >{<span>Shift Bid Revoked</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRevoked.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidRevoked.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'bidRevoked', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRevoked.notify}
                checked={employee.settings.notification.bidRevoked.sendToEmail1}
                onChange={e => updateNotificationSettings({ type: 'bidRevoked', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRevoked.notify}
                checked={employee.settings.notification.bidRevoked.sendToPhone1}
                onChange={e => updateNotificationSettings({ type: 'bidRevoked', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRevoked.notify}
                checked={employee.settings.notification.bidRevoked.sendToEmail2}
                onChange={e => updateNotificationSettings({ type: 'bidRevoked', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidRevoked.notify}
                checked={employee.settings.notification.bidRevoked.sendToPhone2}
                onChange={e => updateNotificationSettings({ type: 'bidRevoked', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell {...TD}>
              <Checkbox
                disabled={employeeDisabled}
                checked={employee.settings.notification.bidLost.notify}
                onChange={e => updateNotificationSettings({ type: 'bidLost', field: "notify", value: e.target.checked })}
              >{<span>Shift Bid Lost</span>}
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidLost.notify}
                checked={employee.settings.notification ? employee.settings.notification.bidLost.inApp : false}
                onChange={e => updateNotificationSettings({ type: 'bidLost', field: "inApp", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidLost.notify}
                checked={employee.settings.notification.bidLost.sendToEmail1}
                onChange={e => updateNotificationSettings({ type: 'bidLost', field: "sendToEmail1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidLost.notify}
                checked={employee.settings.notification.bidLost.sendToPhone1}
                onChange={e => updateNotificationSettings({ type: 'bidLost', field: "sendToPhone1", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidLost.notify}
                checked={employee.settings.notification.bidLost.sendToEmail2}
                onChange={e => updateNotificationSettings({ type: 'bidLost', field: "sendToEmail2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
            <TableCell {...TDC}>
              <Checkbox
                disabled={!employee.settings.notification.bidLost.notify}
                checked={employee.settings.notification.bidLost.sendToPhone2}
                onChange={e => updateNotificationSettings({ type: 'bidLost', field: "sendToPhone2", value: e.target.checked })}
              >
              </Checkbox>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  * end of WiP Notificaitons */

  const LeaveTable = () => {
    const leave = employee.leave
    const { compTime, executiveAccruals, floatingHolidayAccruals } = leave
    const { fireLeaveBank, sickAccruals, vacationAccruals } = leave
    const { holidayAccruals, famMedLeaveAccruals, otherLeave } = leave
    return (
      <Table className="employeeLeaveTable">
        <TableHeader key={uuid()}>
          <TableRow  key={uuid()} >
            <TableCell key={uuid()} {...TH}>Comp Time</TableCell>
            <TableCell key={uuid()} {...TH}>Executive</TableCell>
            <TableCell key={uuid()} style={ { display: 'none'}} {...TH}>Fire</TableCell>
            <TableCell key={uuid()} {...TH}>Sick</TableCell>
            <TableCell key={uuid()}{...TH}>Vacation</TableCell>
            <TableCell key={uuid()} {...TH}>Holiday</TableCell>
            <TableCell key={uuid()} {...TH}>Floating Holiday</TableCell>
            <TableCell key={uuid()} style={ { display: 'none'}} {...TH}>Family Medical</TableCell>
            <TableCell key={uuid()} {...TH}>Other</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody  key={uuid()}>
          <TableRow key={uuid()}>
            <TableCell key={uuid()} {...TD}>{compTime}</TableCell>
            <TableCell key={uuid()} {...TD}>{executiveAccruals}</TableCell>
            <TableCell key={uuid()} style={ { display: 'none'}} {...TD}>{fireLeaveBank}</TableCell>
            <TableCell key={uuid()} {...TD}>{sickAccruals}</TableCell>
            <TableCell key={uuid()} {...TD}>{vacationAccruals}</TableCell>
            <TableCell key={uuid()} {...TD}>{holidayAccruals}</TableCell>
            <TableCell key={uuid()} {...TD}>{floatingHolidayAccruals}</TableCell>
            <TableCell key={uuid()} style={ { display: 'none'}} {...TD}>{famMedLeaveAccruals}</TableCell>
            <TableCell key={uuid()} {...TD}>{otherLeave}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const SettingsTable = () => {
    return (
      <Table>
        <TableHeader key={uuid()}>
          <TableRow key={uuid()}>
            <TableCell key={uuid()} {...TH}>Verification</TableCell>
            <TableCell key={uuid()} {...TH}> </TableCell>
            <TableCell key={uuid()} {...TH}>Field</TableCell>
            <TableCell key={uuid()} {...TH}>Notify</TableCell>
            <TableCell key={uuid()} {...TH}>Input</TableCell>
            {/* <TableCell {...TH}>Login</TableCell> */}
          </TableRow>
        </TableHeader>
        <TableBody key={uuid()}>
          {/* ORACLE EMAIL */}
          <TableRow key={uuid()}>
            <TableCell {...TD}>
              <SilentInvite field="email" value={employee.email} />
            </TableCell>
            <TableCell key={uuid()} {...TD}>
              <Verify disabled={true} field="email1Verified" />
            </TableCell>
            <TableCell key={uuid()} {...TD}>Oracle Email</TableCell>
            <TableCell key={uuid()} {...TD}>
              <Checkbox
                disabled={employee.email ? false : true}
                checked={employee.settings.sendToEmail1}
                onChange={e => updateSettings({ field: "sendToEmail1", value: e.target.checked })}
              />
            </TableCell>
            <TableCell key={uuid()} {...TD}>
              <Input
                disabled={true}
                className="emailInput"
                placeholder="No email in Oracle"
                defaultValue={employee.email}
                onBlur={e => updateEmployee({ field: "email", value: e.target.value })}
              />
            </TableCell>
          </TableRow>

          {/* SECONDARY EMAIL */}
          <TableRow key={uuid()}>
            <TableCell key={uuid()} {...TD}>
              <SilentInvite disabled={employee.email2 ? false : true} field="email2" />
            </TableCell>
            <TableCell key={uuid()} {...TD}>
              <Verify disabled={employee.email2 ? false : true} field="email2Verified" />
            </TableCell>
            <TableCell key={uuid()} {...TD}>Secondary Email</TableCell>
            <TableCell key={uuid()} {...TD}>
              <Checkbox
                disabled={employee.email2 ? false : true}
                checked={employee.settings.sendToEmail2}
                onChange={e => updateSettings({ field: "sendToEmail2", value: e.target.checked })}
              />
            </TableCell>
            <TableCell key={uuid()} {...TD}>
              <Input
                disabled={false}
                className="emailInput"
                placeholder="other email"
                defaultValue={employee.email2}
                onBlur={e => updateEmployee({ field: "email2", value: e.target.value })}
              />
            </TableCell>
          </TableRow>

          {/* ORACLE PHONE */}
          <TableRow key={uuid()}>
            <TableCell key={uuid()} {...TD}></TableCell>
            <TableCell key={uuid()} {...TD}>
              <Verify disabled={employee.phone ? false : true} field="phone1Verified" />
            </TableCell>
            <TableCell key={uuid()} {...TD}>Oracle Phone</TableCell>
            <TableCell key={uuid()} {...TD}>
              <Checkbox
                disabled={employee.phone ? false : true}
                checked={employee.settings.sendToPhone1}
                onChange={e => updateSettings({ field: "sendToPhone1", value: e.target.checked })}
              />
            </TableCell>
            <TableCell key={uuid()} {...TD}>
              <PhoneInput
                autoFormat={true}
                disabled={true}
                {...phoneProps}
                value={employee.phone ? employee.phone.replace("+1", "") : "No Phone Number in Oracle"}
                onBlur={e => {
                  const val = e.target.value
                  const value = val ? val.replace(" ", "") : null
                  // const valid = value && value.length === 10
                  updateEmployee({ field: "phone", value })
                }}
              />
            </TableCell>
          </TableRow>

          {/* SECONDARY PHONE */}
          <TableRow key={uuid()}>
            <TableCell key={uuid()} {...TD}> </TableCell>
            <TableCell key={uuid()} {...TD}>
              <Verify disabled={employee.phone2 ? false : true} field="phone2" />
            </TableCell>
            <TableCell key={uuid()} {...TD}>Secondary Phone</TableCell>
            <TableCell key={uuid()} {...TD}>
              <Checkbox
                disabled={employee.phone2 ? false : true}
                checked={employee.settings.sendToPhone2}
                onChange={e => updateSettings({ field: "sendToPhone2", value: e.target.checked })}
              />
            </TableCell>
            <TableCell key={uuid()} {...TD}>
              <PhoneInput
                autoFormat={true}
                disabled={false}
                {...phoneProps}
                value={employee.phone2 ? employee.phone2.replace("+1", "") : ""}
                onBlur={e => {
                  const val = e.target.value
                  const value = val ? val.replace(" ", "") : null
                  //console.log("PHONE 2", value)
                  // const valid = value && value.length === 10
                  updateEmployee({ field: "phone2", value })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const roleOptions = [
    { value: "employee", label: "Employee" },
    { value: "supervisor", label: "Supervisor" },
    { value: "admin", label: "Admin" },
    { value: "disabled", label: "Disabled" },
  ]

  useEffect(() => {
    fetchEmployee()
    setState({ ...state, mounted: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ( 
    <React.Fragment>
      {!employee && <div>LOADING...</div>}
      {employee && (
        <div className="employee">
          <div className="view_title">
            {mySettings && <h1 data-cy="page-title">My Settings</h1>}
            {!mySettings && <h1 data-cy="page-title">{`${employee.firstName} ${employee.lastName}`}</h1>}
          </div>
          {!mySettings && (
            <div className="Breadcrumb">
              <Can I="view:Breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item key="home">
                  <span onClick={() => props.history.push(`/nav`)}>
                    <Icon type="home" />
                  </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="teams">
                  <span data-cy="back" onClick={() => props.history.push(`/admin/employees`)}>
                    <Icon type="team" />
                    <span>employees</span>
                  </span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{employee.fullName}</Breadcrumb.Item>
              </Breadcrumb>
              </Can>
            </div>
          )}
          <div className="view_content">
            <Content className="UserSettings">
              <Accordion
                multiple
                activeIndex={active}
                onActive={a => {
                  setActive(a)
                }}
              >
                <AccordionPanel label="Leave Balances">
                  <Box background="white" pad="medium" margin="medium">
                    <LeaveTable />
                  </Box>
                </AccordionPanel>
                <AccordionPanel label="Settings">
                  <Box background="white" pad="medium" margin="medium">
                    <SettingsTable />
                  </Box>
                </AccordionPanel>
                {/* WIP Notifications}
                <AccordionPanel label="Notifications" className="collapse in">
                  <NotificationRequestTable />
                  <Box background="white" pad="medium" margin="medium">
                    <NotificationResponseTable />
                  </Box>
                </AccordionPanel>
              * end of WIP Notifications */}
                <AccordionPanel label="Specialties">
                  <Box background="white" pad="medium" margin="medium">
                    <section className="input labelLeft">
                      {/*<div className="value">
                        {employee.isSwat && <span className="pill swat"> swat </span>}
                        {employee.isDive && <span className="pill dive"> dive </span>}
                        {employee.isCanine && <span className="pill canine"> K9 </span>}
                        {employee.isFieldTraining && <span className="pill fto"> FTO </span>}
                        {employee.isMoto && <span className="pill moto"> moto </span>}
                      </div>*/}
              
                        <Checkbox
                          disabled={true}
                          checked={employee.isCanine}
                          onChange={e => updateSettings({ field: "isCanine", value: e.target.checked })}
                        >{<span className="pill canine" style={{backgroundColor: stc(`Caninea2`),fontWeight: 600}}> K9 </span>}</Checkbox>
                        <Checkbox
                          disabled={true}
                          checked={employee.isDive}
                          onChange={e => updateSettings({ field: "isDive", value: e.target.checked })}
                        >{<span className="pill dive" style={{backgroundColor: stc(`Divea2`),fontWeight: 600}}>dive</span>}</Checkbox>
                        <Checkbox
                          disabled={true}
                          checked={employee.isFieldTraining}
                          onChange={e => updateSettings({ field: "isFieldTraining", value: e.target.checked })}
                        >{<span className="pill fto" style={{backgroundColor: stc(`Field Traininga2`),fontWeight: 600}}> FTO </span>}</Checkbox>
                        <Checkbox
                          disabled={true}
                          checked={employee.isMoto}
                          onChange={e => updateSettings({ field: "isMoto", value: e.target.checked })}
                        >{<span className="pill moto" style={{backgroundColor: stc(`Motoa2`),fontWeight: 600}}> moto </span>}</Checkbox>
                        <Checkbox
                          disabled={true}
                          checked={employee.isSwat}
                          onChange={e => updateSettings({ field: "isSwat", value: e.target.checked })}
                        >{<span className="pill swat" style={{backgroundColor: stc(`SWATa2`),fontWeight: 600}}> swat </span>}</Checkbox>

                        <div aign={"right"}>
                          <SyncWithJDE field="email" value={employee.email} />
                      </div>
                    </section>
                  </Box>
                </AccordionPanel>
                <AccordionPanel label="Details">
                  <Box background="white" pad="medium" margin="medium">
                    <section className="input labelLeft">
                      <label>Role</label>
                      <div className="value">
                        <Select  
                          isDisabled={ canEditRole ? false : true }
                          options={roleOptions}
                          defaultValue={_.find(roleOptions, { value: employee.role })}
                          classNamePrefix="psrs"
                          className="psrs-select"
                          onChange={e => {
                            //console.log(e.value)
                            updateEmployee({ field: "role", value: e.value })
                          }}
                        />
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>First Name</label>
                      <div className="value">
                        <Input disabled value={employee.firstName} />
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>Last Name</label>
                      <div className="value">
                        <Input disabled value={employee.lastName} />
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>Badge Number</label>
                      <div className="value">
                        <Input
                          defaultValue={employee.badgeNumber}
                          onBlur={e => {
                            updateEmployee({ field: "badgeNumber", value: e.target.value })
                          }}
                        />
                      </div>
                    </section>

                    <section className="input labelLeft" style={ { display: 'none'}}>
                      <label>Groups</label>
                      <div className="value">
                        <TagSelect
                          disabled={true}
                          record={employee}
                          field="tagIds"
                          handleChange={v => updateEmployee({ field: "tagIds", value: v.value })}
                        />
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>employee Number</label>
                      <div className="value">
                        <Tag>{employee.number}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>Job Title</label>
                      <div className="value">
                        <Tag>{employee.jobTitle}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>Supervisor</label>
                      <div className="value">
                        <Tag>{employee.supervisor.jobTitle} </Tag> {employee.supervisor.fullName}
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>Home Business Unit</label>
                      <div className="value">
                        <Tag>{employee && employee.businessUnitCode ? employee.businessUnitCode : null} </Tag>{employee && employee.businessUnit && employee.businessUnit.name ? employee.businessUnit.name : null}
                      </div>
                    </section>

                    <section className="input labelLeft" style={ { display: 'none'}}>
                      <label>assignment</label>
                      <div className="value">
                        <AssignmentSelect
                          disabled={true}
                          value={employee.assignmentId}
                          field="assignmentId"
                          handleChange={v => updateEmployee({ field: "assignmentId", value: v.value })}
                        />
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>shift differential</label>
                      <div className="value"> {employee.shiftDifferential} </div>
                    </section>

                    <section className="input labelLeft">
                      <label>job step code</label>
                      <div className="value">
                        <Tag>{employee.jobStepCode}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>pay class code</label>
                      <div className="value">
                        <Tag>{employee.payClassCode}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>pay status code</label>
                      <div className="value">
                        <Tag>{employee.payStatusCode}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>start date</label>
                      <div className="value">
                        <Tag>{employee.startDate}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>union code</label>
                      <div className="value">
                        <Tag>{employee.unionCode}</Tag>
                      </div>
                    </section>

                    <section className="input labelLeft">
                      <label>job code</label>
                      <div className="value">
                        <Tag>{employee.jobCode}</Tag>
                      </div>
                    </section>
                  </Box>
                </AccordionPanel>
              </Accordion>
            </Content>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Employee
