import React from "react"
import { Can, a, employeeNumber, REQ } from "../../store/psrs"
import { Popconfirm, Button, Tag, Tooltip, Icon, Switch} from "antd"
import uid from "uniqid"
import moment from "moment"
// import { log } from "../../store/psrs"
//import {REQ} from "../../store/psrs"
import { CheckBox } from "grommet"
import { ApprovalFinePrint, FinePrint } from "../FinePrint"
import { CheckRequiredfields, ImbalancedMessage, RequiredFieldMessage } from "../ShiftDrawer/CheckRequiredFields"

const _columns = props => {
  const { updateShift, context, ability } = props
  const renderStatus = shift => {
    const { available, requestedOff, approved, imbalanced, rejected, isSick, submit, assignmentId, assignment,locked, paidLunch } = shift
    // const processed = shift.process
    // const paid = { className: `stat ${processed ? "on processed" : ""}`, type: "dollar-circle" }
    // console.log(shift.constructor.modelName)
    const isOT = !!shift.durationOt
    const shiftId = shift.id
    //const canSubmit = ability.can("submit", a("Shift", shift))

    // Gricel and team requested move to 1 hour on 07/28/2021
    //const newCanSubmit = moment(shift.end).subtract(30,"minutes").toISOString() <= moment().toISOString() ? true:false
    const newCanSubmit = moment(shift.end).subtract(1,"hours").toISOString() <= moment().toISOString() ? true:false

    //const newCanSubmit = moment(shift.end).subtract(30,"minutes").toISOString() <= moment('2021-01-25T04:33:00.000Z').toISOString() ? true:false
    //const cantSubmit = ability.cannot("submit", a("Shift", shift))
    const canApprove = ability.can("approve", a("Shift", shift))
    const bids = new Set(shift && shift.bids ? shift.bids : [])
    const canBid = ability.can("bidOn", a("Shift", shift))
    const hasBid = bids.has(employeeNumber)
    
    const submitted = { className: `stat ${submit ? "on submitted" : ""}`, type: "check-circle" }
    const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, type: "clock-circle" }
    const bid = { className: `stat ${hasBid ? "on bidded" : available ? "on available" : ""}`, type: "user-add" }
    const pto = { className: `stat ${requestedOff ? "on pto" : ""}`, type: "message" }
    const sick = { className: `stat ${isSick ? "on sick" : ""}`, type: "medicine-box" }
    const good = { className: `stat ${approved ? "on approved" : ""}`, type: "like" }
    const bad = { className: `stat ${rejected ? "on rejected" : ""}`, type: "dislike" }
    const wrongTime = { className: `stat ${imbalanced ? "on imbalanced" : ""}`, type: "stop" }
    const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, type: "safety-certificate" }
    const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, type: locked ? "lock" :"unlock" }
    const isPaidLunch = paidLunch
    const paidlunch = { className: `stat ${paidLunch ? "on paidLunch": "" }`, type: "rest" }

    const bidOnShift = () => {
      //console.log("bidOnShift")
      if (canBid) {
        if (bids.has(employeeNumber)) bids.delete(employeeNumber)
        else bids.add(employeeNumber)
        // REQ(`/api/resources/shift/${Shift.id}`, "PATCH", { bids: Array.from(bids) })
        //console.log("updating bids", Array.from(bids))
        updateShift({ field: "bids", value: Array.from(bids), shiftId })
      }
    }

    const validateFields = async (shift) => {
      let valid = true

      if (!shift.submit) {

        const { checkFields, okSubmit } = await CheckRequiredfields(shift)

        if (!okSubmit) {
          valid = false 
          const message = `Required Fields Missing. Please open the Shift Drawer and update the required fields for Shift`
          RequiredFieldMessage(shift,checkFields,message)
        }

        if (shift.imbalanced ) {
          valid = false
          ImbalancedMessage(shift)
        } 
      }
      
      

      return valid
    }

    /*
		const approveEntry = async () => {
    	await REQ(`/api/resources/shift/${shift.id}`, "PATCH", { approved: true, rejected: false })
    	//refresh()
    }
    */

    return (
      <div className="StatusIcons">
        <Can I="see:row:icon:available" this={shift}>
          <Tooltip mouseEnterDelay={canBid ? 0 : 99} title={`${hasBid ? "Remove Bid" : "Bid for Shift"}`}>
            <span className={`statusIcon ${canBid && "hoverPointer"}`} onClick={bidOnShift}>
              <Icon {...bid} />
            </span>
          </Tooltip>
        </Can>

        <Can I="see:row:icon:available" not this={shift}>
          {/* paidLunch */}
          <Tooltip mouseEnterDelay={paidlunch ? 0 : 99} title={ isPaidLunch ? "Paid Lunch" : "Un Paid Lunch" }>
              <span className="statusIcon">
                <Icon {...paidlunch} />
              </span>
          </Tooltip>

          {/* SICK */}
          <Tooltip mouseEnterDelay={isSick ? 0 : 99} title="Sick">
            <span className="statusIcon">
              <Icon {...sick} />
            </span>
          </Tooltip>

          {/* VACATION */}
          <Tooltip mouseEnterDelay={requestedOff ? 0 : 99} title="Time Off">
            <span className="statusIcon">
              <Icon {...pto} />
            </span>
          </Tooltip>

          {/* SUBMIT */}
          <Tooltip mouseEnterDelay={newCanSubmit ? 0 : 99}  title={shift.submit ? 'Un Submit': 'Submit'}>
            <Popconfirm
              disabled = {newCanSubmit ? false : true }
              title={shift.submit ? "Unsubmit Shift" : <FinePrint />}
              onConfirm={ async () => {
                const valid = await (validateFields(shift))       
                  if (newCanSubmit && valid) {
                    // handle isOT notificaiton on submit
                    if (shift.isOT && !shift.submit) {
                        const updateNotification = await REQ(`/api/notifications/${shift.id}`, "POST")
                        console.log('Submit updateNotification -> ', updateNotification)
                     }

                    updateShift({ field: "submit", value: !shift.submit, shiftId: shift.id })

                  } 
              }}
              okText={shift.submit ? "Un Submit" : "Submit"}
              cancelText="Cancel"
            >
              <span className={`statusIcon ${newCanSubmit && "hoverPointer"}`}>
                <Icon {...submitted} />
              </span>
            </Popconfirm>
          </Tooltip>

          {/* OVERTIME */}
          <Tooltip mouseEnterDelay={isOT ? 0 : 99} title="Has Overtime">
            <span className="statusIcon">
              <Icon {...ot} />
            </span>
          </Tooltip>

          {/* IMBALANCED */}
          <Tooltip mouseEnterDelay={imbalanced ? 0 : 99} title="Wrong Time Entry Hours">
            <span className="statusIcon">
              <Icon {...wrongTime} />
            </span>
          </Tooltip>

          {/* ACTING ROLE */}
          <Tooltip mouseEnterDelay={assignmentId ? 0 : 99} title={assignment ? assignment.name : 'Acting Role'}>
            <span className="statusIcon">
              <Icon {...acting} />
            </span>
          </Tooltip>

           {/* REJECTED */}
           <Tooltip mouseEnterDelay={canApprove ? 0 : 99} title={`${shift.rejected ? "Un Reject" : "Reject"}`}>
            <Popconfirm
              disabled={canApprove && !shift.approved ? false : true }
              title={shift.approved ? "Un Reject Shift" : <ApprovalFinePrint/>}
              onConfirm={() => {
                //if (canApprove) approveEntry()
                if (canApprove && !shift.approved) updateShift({ field: "rejected", value: !shift.rejected, shiftId: shift.id })
              }}
              okText={shift.rejected ? "Un Reject" : "Reject"}
              cancelText="Cancel"
            >
              <span className={`statusIcon ${canApprove && "hoverPointer"}`}>
                <Icon {...bad} />
              </span>
            </Popconfirm>
          </Tooltip>

           {/* APPROVED */}
           <Tooltip mouseEnterDelay={canApprove ? 0 : 99} title={`${shift.approved ? "Un Approve" : "Approve"}`}>
            <Popconfirm
              disabled={canApprove && !shift.rejected ? false : true }
              title={shift.approved ? "Un Approve Shift" : <ApprovalFinePrint/>}
              onConfirm={async () => {
                //if (canApprove) approveEntry()
                const valid = await (validateFields(shift))       
                if (canApprove && !shift.rejected && valid) updateShift({ field: "approved", value: !shift.approved, shiftId: shift.id })
              }}
              okText={shift.approved ? "Un Approve" : "Approved"}
              cancelText="Cancel"
            >
              <span className={`statusIcon ${canApprove && "hoverPointer"}`}>
                <Icon {...good} />
              </span>
            </Popconfirm>
          </Tooltip>
          {/* LOCKED */}
          <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ locked? "Locked" : "Not Locked" }>
            <span className="statusIcon">
              <Icon {...isLocked} />
            </span>
          </Tooltip>
        </Can>
      </div>



    )
  }

  /* move to admin screens to refine edits by row
  const zrenderEditable = (value) => {
    return renderEditable.bind(value)
  }

  const [zData, setZData] = useState(null)
  const renderEditable = (cellInfo) => {
    console.log('try it out')
    const theData = makeData()
    setZData(cellInfo.original)

    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          //const data = [...zData];
          const data = zData;
          //data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          data[cellInfo.column.id] =  e.target.innerHTML;
          setZData({ data });
        }}
        dangerouslySetInnerHTML={{
          __html: cellInfo.original[cellInfo.column.id]
        }}
      />
    );
  }
 */

  return [
    {
      Header: "Updated",
      accessor: "updatedAt",
      show: false,
    },
    {
      Header: "ID",
      accessor: "id",
      show: false,
    },
    {
      Header: "Pay Period End",
      accessor: "payPeriod.end",
      show: props.page === "AvailableShiftsWB" ? true : false, 
      className: "justify-around",
      sortable: true,
      fixed: 'left',
      Cell: cell => {
        const date = moment(cell.value).format("MM/DD/YYYY")
        const day = moment(cell.value).format("ddd")
        return (
          <>
            <Tag style={{color: 'white', background: "#1990FF"}}>{day} - {date}</Tag>
          </>
        )
      },
    },
    {
      Header: "Employee",
      //width: 200,
      accessor: "employee.fullName",
      show: props.page === "MyShifts" || props.page === 'AvailableShiftsWB' || props.page === "AvailableShifts" ? false : true,
      sortable: true,
      fixed: 'left',
      //Cell: cell => (cell.value ? cell.value : "Unassigned")
      Cell: cell => (cell.value ? <><Tag style={{fontSize: 'small'}} >{cell.value}</Tag> </> : "Unassigned")

    },
    {
      Header: "Date",
      accessor: "start",
      show: true,
      className: "justify-around",
      //width: 160,
      sortable: true,
      fixed: 'left',
      Cell: cell => {
        const date = moment(cell.value).format("MMM Do")
        const day = moment(cell.value).format("ddd")
        return (
          <>
            <Tag>{day}</Tag> <span>{date}</span>
          </>
        )
      },
    },
    {
      Header: "Start",
      accessor: "start",
      show: true,
      className: "justify-around",
      //width: 80,
      sortable: true,
      fixed: 'left',
      Cell: cell => moment(cell.value).format("HH:mm"),
    },
    {
      Header: "End",
      accessor: "end",
      show: true,
      className: "justify-around",
      //width: 80,
      sortable: false,
      fixed: 'left',
      Cell: cell => moment(cell.value).format("HH:mm"),
    },
    {
      Header: "scheduled",
      accessor: "scheduled",
      show: false,
      className: "justify-around",
    },
    {
      Header: "Worked",
      accessor: "worked",
      show: false,
      className: "justify-around",
      Cell: cell => parseFloat(cell.value).toFixed(2),
    },
    {
      Header: "Hours",
      accessor: "duration",
      show: true,
      className: "justify-around",
      //width: 80,
      sortable: true,
      fixed: 'left',
      //Cell: cell => renderEditable(cell)
    },
    {
      Header: "Pay Type",
      accessor: 'payType',
      className: "justify-around",
      show: props.page === 'AvailableShiftsWB' ? true : false,
      sortable: true,
      //Cell: cell => {
      //  return cell.value ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
     // }
      Cell: cell => {

        const payTypeDesc = cell.value.description ?  cell.value.rename : cell.value.description
        const payTypeCode = cell.value.code

        return (
          <Tooltip title={`${payTypeCode} - ${payTypeDesc}`}>
              <Tag style={{fontSize: 'small'}} >{payTypeCode}</Tag>
          </Tooltip>
        )

      } 
    },
    {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      style: { overflowWrap: "break-word" },
      //width: 80,
      show: false,
      sortable: true,
      filterable: false,
      Cell: cell => <> <CheckBox pad="xxsmall" disabled={true} checked={!cell.value}></CheckBox></>,
    },
    {
      Header: "Acting Role",
      accessor: 'assignment.name',
      show: true,
      className: "justify-around",
      sortable: false,
      Cell: cell => cell.value ? <><Tag style={{fontSize: 'x-small'}}>{cell.value}</Tag> </> : ''
    },
    {
      Header: "Op Assgnment",
      accessor: "operationalAssignment",
      show: true,
      className: "justify-around",
      //width: 200,
      sortable: true,
      Cell: cell => cell.value ? <><Tag style={{fontSize: 'x-small'}} >{cell.value}</Tag> </> : ''
    },
    {
      Header: "Charge Out",
      accessor: "costCenterChargeOutCode",
      show: false,
      //className: "justify-around",
    },
    {
      Header: "subLedger",
      accessor: "subLedger",
      show: false,
      //className: "justify-around",
    },
    {
      Header: "Supervisor 1",
      //width: 180,
      accessor: "supervisor1.fullName",
      //show: props.page === "SupervisedShifts" ? false : true,
      show: props.page === "DirectSupervisedShifts" ? false : true,
      sortable: true,
      //Cell: cell => (cell.value ? cell.value : ""),
      //Cell: cell => cell.value ? <><Tag style={{fontSize: 'x-small'}} >{cell.value}</Tag> </> : ''
      Cell: row => {

        const s1 = row && row.original && row.original.supervisor1 && row.original.supervisor1.number ? row.original.supervisor1.number : ''
        const s2 = row && row.original && row.original.supervisor2 && row.original.supervisor2.number ? row.original.supervisor2.number : ''
        const s3 = row && row.original && row.original.supervisor3 && row.original.supervisor3.number ? row.original.supervisor3.number : ''

        const s1Name = row && row.original && row.original.supervisor1 && row.original.supervisor1.lastName ? row.original.supervisor1.lastName : ''
        const s2Name = row && row.original && row.original.supervisor2 && row.original.supervisor3.lastName ? row.original.supervisor2.lastName : ''
        const s3Name = row && row.original && row.original.supervisor3 && row.original.supervisor3.lastName ? row.original.supervisor3.lastName : ''

        const supervisor = row.value ?
       
        <Tooltip title={
          <small><ol>
            <li>Supervisor 1: {s1} / {s1Name}</li>
            <li>Supervisor 2: {s2} / {s2Name}</li>
            <li>Supervisor 3: {s3} / {s3Name}</li>
          </ol></small>
          }>
            <Tag style={{fontSize: 'small'}} >{row.value}</Tag>
        </Tooltip> 
        :
        ''
        
        return supervisor
      } 
    },
    {
      Header: "Supervisor 2",
      //width: 180,
      accessor: "supervisor2.fullName",
      show: false,
      sortable: true,
      //Cell: cell => (cell.value ? cell.value : ""),
      Cell: cell => cell.value ? <><Tag style={{fontSize: 'x-small'}} >{cell.value}</Tag> </> : ''
    },
    {
      Header: "Supervisor 3",
      //width: 180,
      accessor: "supervisor3.fullName",
      show: false,
      sortable: true,
      //Cell: cell => (cell.value ? cell.value : ""),
      Cell: cell => cell.value ? <><Tag style={{fontSize: 'x-small'}} >{cell.value}</Tag> </> : ''
    },
    {
      Header: "Groups",
      accessor: "tags",
      show: true,
      sortable: false,
      filterable: false,
      Cell: cell => {
        const values = cell.value && Array.isArray(cell.value)
        return values ? cell.value.map(item => <Tag key={uid()} style={{fontSize: 'x-small'}}>{item.name}</Tag>) : ""
      },
    },
    {
      Header: "Taking Bids",
      accessor: "takingBids",
      show: props.page === 'AvailableShiftsWB' ? true : false,
      className: "justify-around",
      sortable: false,
      //Cell: cell => cell.value ? "TRUE":"FALSE",
      //Cell: row => <Toggle disabled={false} toValue={true} unValue={false} value={row.value} field='takingBids' id={row.original.id} {...{ 
        //state, 
        //setState, 
        //mutate, 
        //url: key,
        //model: "shift",
        //mutate: ()=>false, 
        //checkedChildren: <Icon type="check" />,
        //unCheckedChildren: <Icon type="close" />,
       //}} />,
      Cell: row => <Switch
      disabled={false}
      checked={row.value}
      checkedChildren={<Icon type="check" />}
      unCheckedChildren={<Icon type="close" />}
      onChange={e => {
        updateShift({ field: "takingBids", value: e, shiftId: row.original.id })        

      }} 
    />

    },
    {
      Header: "Business Unit",
      accessor: "businessUnit",
      show: false,
      className: "justify-around",
      sortable: false,
      Cell: cell => {
        if (!cell.value) return ""
        return (
          <Tooltip title={cell.value.descri || "none"}>
            <Tag>{cell.value.code || "none"}</Tag>
          </Tooltip>
        )
      },
    },
    {
      Header: "Time Entry Ct",
     // width: 140,
      show: false,
      accessor: "timeEntries.length",
      className: "justify-around",
      sortable: true,
      Cell: cell => (cell.value ? cell.value : 0),
    },
    {
      Header: "",
      show: props.page === 'AvailableShiftsWB' ? false : true,
      className: "justify-around",
      width: 200,
      sortable: false,
      filterable: false,
      fixed: 'right',
      Cell: cell => renderStatus(cell.original),
    },
    {
      Header: "Actions",
      accessor: "id",
      show: true,
      className: "justify-around",
      width: 120,
      sortable: false,
      filterable: false,
      Cell: cell => (
        <Button
          value={cell.value}
          size="small"
          icon="eye"
          onClick={e => {
            context.setShiftDrawerId(cell.value)
          }}
        >
          View
        </Button>
      ),
    },
  ]
}

export default _columns
